import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Button from '../components/Button'
import Carto from '../components/Carto';
import '../public/styles/suivi.css'

import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { PDFDownloadLink } from '@react-pdf/renderer';
//ant
import { LaptopOutlined } from '@ant-design/icons';

import { Link } from 'react-router-dom';

import logoAsterion from "../public/images/ASTERION PARTNERS copie 2.png"


//COMPONENTS______________________________________________
import Nav from '../components/Nav'
import ToggleSlide from '../components/ToggleSlide'
import Farmer from '../components/Farmer'
import MarciR from '../components/MarciR'
import Radian from '../components/Radian'
import Informations from '../components/Informations'
import Incidents from '../components/Incidents'
import Actions from '../components/Actions'
import Audits from '../components/Audits'
import SideList from '../components/SideList'
import SideInfos from '../components/SideInfos'
import Strategie from '../components/Strategie'

//Icons___________________________________________________

//moment
import moment from "moment/min/moment-with-locales"

//antd____________________________________________________
import { Drawer, Checkbox, Form } from 'antd';

//REDUX___________________________________________________
import { useDispatch, useSelector } from 'react-redux'

import Rapport from '../components/Rapport';

moment.locale('fr')

const Suivi = () => {

    const [isDesktop, setIsDesktop] = useState(false)

    const [ready, setReady] = useState(true)

    const { stepFromCreate, stateFromCreate, toggleFromHome } = useParams()
    //console.log(stateFromCreate, stepFromCreate, toggleFromHome)

    const [form] = Form.useForm()

    const [volet, setVolet] = useState(toggleFromHome === 'incident' ? true : false)

    //redux
    const dispatch = useDispatch()

    const [actualisation, setActualisation] = useState(false)

    const user = useSelector(({ user }) => user)
    const clientSelected = useSelector(({ clientSelected }) => clientSelected)
    //useEffect(()=>{console.log({clientSelectedInSuivi: clientSelected})}, [clientSelected])
    const listFollowed = useSelector(({ listFollowed }) => listFollowed)
    const listByRisks = useSelector(({ listByRisks }) => listByRisks)
    const listByProcesses = useSelector(({ listByProcesses }) => listByProcesses)
    const clientUsers = useSelector(({ clientUsers }) => clientUsers)
    const planSelected = useSelector(({ planSelected }) => planSelected)
    const incidentSelected = useSelector(({ incidentSelected }) => incidentSelected)

    //states for toggles slides blobal + 2 sub toggles
    const [toggle, setToggle] = useState(0)
    const [toggleGraph, setToggleGraph] = useState(0)
    const [toggleSuivi, setToggleSuivi] = useState(0)

    const [stateSelected, setStateSelected] = useState(listByProcesses ? stateFromCreate ? listFollowed.find(e => e._id === stateFromCreate) : listByProcesses[0]?.family?.processes[0]?.states[0] : null)

    const [tempState, setTempState] = useState()

    //antd Drawer items
    const [visible, setVisible] = useState(false)
    const [visibleReport, setVisibleReport] = useState(false)

    //filtered list report
    const [listReport, setListReport] = useState(listFollowed?.filter(g => g.proprietaire._id === user._id))

    //mini modal confirmation suppression (ne plus suivre)
    const [confirmation, setConfirmation] = useState(false)


    const [audits, setAudits] = useState([])

    const [auditSelected, setAuditSelected] = useState({})

    const [stateId, setStateId] = useState('')

    const [statesWithReco, setStatesWithReco] = useState([])

    useEffect(() => {
        const fetchStatesAndRecos = async (auditId) => {
            const data = await fetch(`${process.env.REACT_APP_BACK_END}/audits/get-audit-and-recos/${auditId}`) //fetch state + reco le concernant dans l'audit sélectionné si existante
            const json = await data.json()

            if (json.result) {
                // console.log(json.statesWithRecos)
                setStatesWithReco(json.statesWithRecos)
            }
        }
        if (Object.keys(auditSelected).length > 0) { fetchStatesAndRecos(auditSelected._id) }
    }, [auditSelected, clientSelected])

    useEffect(() => {
        const getAudits = async (clientId) => {

            const data = await fetch(`${process.env.REACT_APP_BACK_END}/audits/get-audits/${clientId}`)
            const json = await data.json()

            if(json.result) {
                //  console.log("====================", json.auditsFound)
                setAudits(json.auditsFound)
                dispatch({type: 'fetchAudits', audits: json.auditsFound})
            }

        }
        if (clientSelected) { getAudits(clientSelected._id) }

    }, [clientSelected, auditSelected])


    // useEffect(()=>{console.log({listReport})}, [listReport])

    // useEffect(() => { console.log(listFollowed) }, [listFollowed])
    // useEffect(()=>{console.log({stateSelected})}, [stateSelected])


    const unfollow = async (client, state) => {

        let hab = clientSelected.type_hab
        const data = await fetch(`${process.env.REACT_APP_BACK_END}/clients/unfollow/${client}/${state}`)
        const json = await data.json()

        if(json.result){
            // console.log('State unfollowed : ', json.client)
            dispatch({ type: 'selectstate', state: null })
            dispatch({type: 'selectClient', client: {...json.client, type_hab: hab}})            
        }

    }


    const addOrRemove = (item) => {
        let temp = [...listReport]

        if (temp.find(e => e._id === item._id)) {
            //console.log({alreadyExist: temp.find(e=>e._id === item._id)})
            temp.splice(temp.findIndex(e => e._id === item._id), 1)
        } else {
            //console.log({alreadyExist: temp.find(e=>e._id === item._id)})
            temp.push(item)
        }

        setListReport(temp)
    }

    const selectAll = () => {
        if (listReport.length > 0) {
            setListReport([])
        } else {
            setListReport(listFollowed)
        }
    }

    const toggleDrawer = () => {
        setVisible(!visible);
    };

    const toggleDrawerReport = () => {
        setVisibleReport(!visibleReport);
    };



    //LISTENERS
    //useEffect(() => { if (user) { console.log('user in Suivi : ', user) } }, [user])
    // useEffect(() => { if (clientSelected) { console.log('clientSelected in Suivi : ', clientSelected) } }, [clientSelected])
    //useEffect(() => { if (listFollowed) { console.log('listFollowed in Suivi : ', listFollowed.length) } }, [listFollowed])
    //useEffect(() => { if (listByRisks) { console.log('listByRisks in Suivi : ', listByRisks) } }, [listByRisks])
    //useEffect(() => { if (listByProcesses) { console.log('listByProcesses in Suivi : ', listByProcesses) } }, [listByProcesses])
    // useEffect(() => { if (clientUsers) { console.log('clientUsers in Suivi : ', clientUsers) } }, [clientUsers])

    //MAINTAIN STATESELECTED

    useEffect(() => {
        if (stepFromCreate) { toggleNumSuivi(stepFromCreate - 1) }
    }, [stepFromCreate])

    useEffect(() => {
        if (stateSelected) { dispatch({ type: 'selectstate', state: stateSelected }) }
    }, [stateSelected])

    useEffect(() => {
        let stateToSelect = listFollowed.find(e => e._id === stateFromCreate)
        //console.log({stateToSelect}, {stateFromCreate}, listFollowed.length)
        if (stateFromCreate && stateToSelect) { dispatch({ type: 'selectstate', state: stateToSelect }) }
    }, [stateFromCreate])


    //associated functions
    const toggleNum = (i) => {
        setToggle(i)
    }

    const toggleNumGraph = (i) => {
        setToggleGraph(i)
    }

    const toggleNumSuivi = (i) => {
        setToggleSuivi(i)
    }

    const toggleAudit = () => {
        setToggle(5)
    }


    //REACT PDF/////////////////////////////////////////////////////////////////////////////////////////////////
    // Create styles
    const styles = StyleSheet.create({
        first: {
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: '#E4E4E4',
            alignItems: 'center'
        },
        page: {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            backgroundColor: '#E4E4E4'
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        },
        title: {
            textAlign: 'center',
            fontSize: 37,
            margin: 80
        },
        subtitle: {
            margin: 15,
            fontSize: 12
        },
        subtitlef: {
            margin: 15,
            fontSize: 16
        },
        text: {
            margin: '10px 10px 10px 25px',
            fontSize: 10
        }
    });

    // Create Document Component ////////////////
    const MyDocument = () => (
        <Document>
            <Page style={styles.first}>
                <Image style={{ height: '120px', marginTop: '100px' }} src={clientSelected.logo} />
                <Text style={styles.title}>Cartographie des risques</Text>
                <Text style={styles.subtitlef}>au {moment(new Date()).format('LL')}</Text>
                <Image style={{ width: '200px' }} src={logoAsterion} />
            </Page>

            {
                listReport.map((risk, i) => {

                    return (
                        <>
                            <Page key={i + 'a'} size="A4" style={styles.page}>
                                <View wrap={false} style={styles.section}>
                                    <Text>{risk.intitule}</Text>
                                    <Text style={styles.subtitle}>Informations</Text>
                                    <Text style={styles.text}>Le risque {risk.intitule} appartient à la famille de risque : {risk.risk?.family?.libelle} et au processus métier : {risk.process?.libelle}</Text>
                                    <Text style={styles.text}>Le risque {risk.intitule} a été créé par {risk.creator ? `${risk.creator?.firstname} ${risk.creator?.lastname}` : 'un utilisateur inconnu'}. Son propriétaire est {risk.proprietaire ? `${risk.proprietaire?.firstname} ${risk.proprietaire?.lastname}` : 'inconnu'}.</Text>

                                    <Text style={styles.subtitle}>Evènement rattaché</Text>
                                    <Text style={styles.text}>{risk.event}</Text>

                                    <Text style={styles.subtitle}>Causes</Text>
                                    <Text style={styles.text}>{risk.causes}</Text>

                                    <Text style={styles.subtitle}>Conséquences</Text>
                                    <Text style={styles.text}>{risk.consequences}</Text>
                                </View>
                            </Page>

                            <Page key={i + 'b'} style={styles.section}>
                                <View wrap={false} style={styles.section}>
                                    <Text style={styles.subtitle}>Données métiers relevées par les équipes</Text>
                                    <Text style={styles.text}>Probabilité brute : {risk.lvl_proba?.brut} - Impact brut : {risk.lvl_impact?.brut} - Maîtrise : {risk.lvl_maitrise} {risk.dispositif_maitrise ? `avec un dispositif de maîtrise ${risk.dispositif_maitrise}` : ''}</Text>

                                    <Text style={styles.subtitle}>Données métiers à l'aune de leur interaction</Text>
                                    <Text style={styles.text}>Probabilité nette : {risk.lvl_proba?.net} - Impact net : {risk.lvl_impact?.net}</Text>

                                    <Text style={styles.subtitle}>Données décisionnelles</Text>
                                    <Text style={styles.text}>Criticité : {risk.crit} - Vulnérabilité : {risk.vuln}  - Rapport gain/coût : {risk.rapport} - Maîtrise optimale : {risk.prediction}</Text>
                                </View>
                            </Page>

                            <Page key={i + 'c'} style={styles.section}>
                                <View style={styles.section}>
                                    <Text style={styles.subtitle}>Incidents relevés</Text>
                                    {risk.incident.length > 0 ?
                                        risk.incident.map((inc) => {
                                            return (<>
                                                <Text style={styles.text}>Objet : {inc.objet}</Text>
                                                <Text style={styles.text}>Date de l'incident : {moment(inc.date_incident).format('LL')} - Date de déclaration : {moment(inc.date_creation).format('LL')}</Text>
                                                <Text style={styles.text}>Lieu : {inc.lieu} - Service : {inc.service}</Text>
                                                <Text style={styles.text}>Description : {inc.descr_incident}</Text>
                                                <Text style={styles.text}>Impact ressenti : {inc.impact_ressenti} {inc.nature_impact.length > 0 ? ` sur le plan : ${inc.nature_impact.map(e => { return ` ${e}` })}` : ''}</Text>
                                            </>
                                            )
                                        })
                                        :
                                        <Text style={styles.text}>Aucun incident relevé à ce jour</Text>
                                    }
                                </View>
                            </Page>

                            <Page key={i + 'd'} style={styles.section}>
                                <View style={styles.section}>
                                    <Text style={styles.subtitle}>Plan d'action</Text>
                                    {risk.action.length > 0 ?
                                        risk.action.map((act) => {
                                            return (<>
                                                <Text style={styles.text}>Origine : {act.origine}</Text>
                                                <Text style={styles.text}>Services associés : {act.services_associes}</Text>
                                                <Text style={styles.text}>Objet : {act.objet}</Text>
                                                <Text style={styles.text}>Date de début : {moment(act.date_debut).format('LL')} - Date de fin : {moment(act.date_fin).format('LL')} {act.date_closure ? ` - Date de clôture : ${moment(act.date_closure).format('LL')}` : ''}</Text>
                                                <Text style={styles.text}>Description : {act.descr_action}</Text>
                                                <Text style={styles.text}>Maîtrise cible : {act.maitrise_cible}</Text>
                                            </>
                                            )
                                        })
                                        :
                                        <Text style={styles.text}>Aucun plan d'action à ce jour</Text>
                                    }
                                </View>
                            </Page>

                            <Page key={i + 'e'} style={styles.section}>
                                <View style={styles.section}>
                                    <Text style={styles.title}>Versions antérieures</Text>
                                    {risk.iterations.length > 0 ?
                                        risk.iterations.map((ite) => {
                                            return (
                                                <>
                                                    <Text style={styles.text}>· Version du {moment(ite.date_creation).format('LL')}</Text>
                                                </>
                                            )
                                        })
                                        :
                                        <Text style={styles.text}>Aucune version antérieure</Text>
                                    }
                                </View>
                            </Page>

                        </>
                    )
                })
            }

        </Document>
    )
    // END REACT PDF ////////////////////////////////////////////////////////////////////////////////////////////

    //render/////////////////////////////////////////////////////////////////////
    return (
        <>

            {!isDesktop &&
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 50000, position: 'absolute', textAlign: 'center', fontSize: 20, height: '100%', width: '100%', backgroundColor: '#333', opacity: 0.99, color: '#FFF', fontWeight: 'bold' }}>
                    <LaptopOutlined style={{ fontSize: 200, color: "#EDB30D", marginBottom: 40 }} />
                    <span style={{ margin: '20px 0px' }}>Votre écran est un peu petit : cela risque d'empêcher le rendu de certains graphiques et de données modélisées.</span>
                    <span style={{ margin: '20px 0px' }}>Agrandissez la fenêtre du navigateur ou installez-vous sur un écran de taille supérieure pour profiter pleinement de l'application 😀</span>
                </div>
            }

            <Nav actualisation={actualisation} setActualisation={setActualisation} ready={ready} setReady={setReady} isDesktop={isDesktop} setIsDesktop={setIsDesktop} />

            <div className="global">
                <div className='legend' style={{ justifyContent: 'space-between', width: '55%' }}>
                    <ToggleSlide
                        defaultValue={toggleFromHome === 'graph' || toggleFromHome === 'graphradian' ? 1 : toggleFromHome === 'audit' ? 5 : 0}
                        toggleNum={toggleNum}
                        toggle={toggle}
                        values={["Risques", "Graphiques", "Cartographie"]}
                    />

                    {toggle === 1 &&
                        <ToggleSlide
                            defaultValue={toggleFromHome === 'graphradian' ? 2 : 0}
                            toggleNum={toggleNumGraph}
                            toggle={toggleGraph}
                            values={["Farmer", "Marci", "Radian annulaire"]}
                        />
                    }

                    {toggle === 0 &&
                        <ToggleSlide
                            defaultValue={toggleFromHome === 'incident' ? 1 : toggleFromHome === 'action' ? 3 : 0}
                            toggleNum={toggleNumSuivi}
                            toggle={toggleSuivi}
                            values={["Définition", "Exposition", "Décision", "Action"]}
                        />
                    }

                    {toggle !== 5 &&
                        <div className='buttonbackstyle' onClick={() => { toggleAudit() }} ><div className='buttonstyle'>Audit</div></div>
                    }
                </div>

                {toggle === 0 && <SideList side='left' clientUsers={clientUsers} stateSelected={stateSelected} setStateSelected={setStateSelected} user={user} clientSelected={clientSelected} listFollowed={listFollowed} listByRisks={listByRisks} listByProcesses={listByProcesses} />}

                {toggle === 1 && toggleGraph === 0 &&
                    <>
                        <Farmer toggleNum={toggleNum} clientUsers={clientUsers} stateSelected={stateSelected} setStateSelected={setStateSelected} user={user} clientSelected={clientSelected} listFollowed={listFollowed} listByRisks={listByRisks} listByProcesses={listByProcesses} />
                        <Rapport toggleDrawerReport={toggleDrawerReport} divid={'reportFarmer'} listFollowed={listFollowed} />
                    </>
                }

                {toggle === 1 && toggleGraph === 1 &&
                    <>
                        <MarciR toggleNum={toggleNum} clientUsers={clientUsers} stateSelected={stateSelected} setStateSelected={setStateSelected} user={user} clientSelected={clientSelected} listFollowed={listFollowed} listByRisks={listByRisks} listByProcesses={listByProcesses} />
                        <Rapport toggleDrawerReport={toggleDrawerReport} divid={'reportMarci'} listFollowed={listFollowed} />
                    </>
                }

                {toggle === 1 && toggleGraph === 2 &&
                    <>
                        <Radian toggleNum={toggleNum} clientUsers={clientUsers} stateSelected={stateSelected} setStateSelected={setStateSelected} user={user} clientSelected={clientSelected} listFollowed={listFollowed} listByRisks={listByRisks} listByProcesses={listByProcesses} />
                        <Rapport toggleDrawerReport={toggleDrawerReport} divid={'reportRadian'} listFollowed={listFollowed} />
                    </>
                }

                {toggle === 2 &&
                    <>
                        <Carto toggleNum={toggleNum} clientUsers={clientUsers} stateSelected={stateSelected} setStateSelected={setStateSelected} user={user} clientSelected={clientSelected} listFollowed={listFollowed} listByRisks={listByRisks} listByProcesses={listByProcesses} />
                        <Rapport toggleDrawerReport={toggleDrawerReport} divid={'Cartographie'} listFollowed={listFollowed} />
                    </>
                }

                {toggle === 0 && toggleSuivi === 0 &&
                    <>
                        <Informations tempState={tempState} clientUsers={clientUsers} stateSelected={stateSelected} setStateSelected={setStateSelected} user={user} clientSelected={clientSelected} listFollowed={listFollowed} listByRisks={listByRisks} listByProcesses={listByProcesses} />
                        <SideInfos slotToSave='suivi' side='right' screen='informations' clientUsers={clientUsers} stateSelected={stateSelected} setStateSelected={setStateSelected} user={user} clientSelected={clientSelected} listFollowed={listFollowed} listByRisks={listByRisks} listByProcesses={listByProcesses} />
                    </>
                }

                {toggle === 0 && toggleSuivi === 1 &&
                    <>
                        <Incidents actualisation={actualisation} setActualisation={setActualisation} volet={volet} setVolet={setVolet} clientUsers={clientUsers} stateSelected={stateSelected} setStateSelected={setStateSelected} user={user} clientSelected={clientSelected} listFollowed={listFollowed} listByRisks={listByRisks} listByProcesses={listByProcesses} />
                        {incidentSelected &&
                            <SideInfos slotToSave='incident' incidentSelected={incidentSelected} side='right' screen='incidents' clientUsers={clientUsers} stateSelected={stateSelected} setStateSelected={setStateSelected} user={user} clientSelected={clientSelected} listFollowed={listFollowed} listByRisks={listByRisks} listByProcesses={listByProcesses} />
                        }
                    </>
                }

                {toggle === 0 && toggleSuivi === 3 &&
                    <>
                        <Actions clientUsers={clientUsers} stateSelected={stateSelected} setStateSelected={setStateSelected} user={user} clientSelected={clientSelected} listFollowed={listFollowed} listByRisks={listByRisks} listByProcesses={listByProcesses} />
                        {planSelected &&
                            <SideInfos slotToSave='action' planSelected={planSelected} side='right' screen='actions' clientUsers={clientUsers} stateSelected={stateSelected} setStateSelected={setStateSelected} user={user} clientSelected={clientSelected} listFollowed={listFollowed} listByRisks={listByRisks} listByProcesses={listByProcesses} />
                        }
                    </>
                }

                {toggle === 0 && toggleSuivi === 2 &&
                    <Strategie clientUsers={clientUsers} stateSelected={stateSelected} setStateSelected={setStateSelected} user={user} clientSelected={clientSelected} listFollowed={listFollowed} listByRisks={listByRisks} listByProcesses={listByProcesses} />
                }

                {toggle === 5 &&
                    <>
                    <Audits stateId={stateId} setStateId={setStateId} statesWithReco={statesWithReco} setStatesWithReco={setStatesWithReco} auditSelected={auditSelected} setAuditSelected={setAuditSelected} toggleNum={toggleNum} audits={audits} setAudits={setAudits} clientUsers={clientUsers} stateSelected={stateSelected} setStateSelected={setStateSelected} user={user} clientSelected={clientSelected} listFollowed={listFollowed} listByRisks={listByRisks} listByProcesses={listByProcesses} />
                   {(Object.keys(auditSelected).length > 0 || stateId) && <SideInfos slotToSave={stateId ? 'reco' : 'audit'} side='right' screen='audit' stateId={stateId} setStateId={setStateId} statesWithReco={statesWithReco} setStatesWithReco={setStatesWithReco} auditSelected={auditSelected} setAuditSelected={setAuditSelected} clientUsers={clientUsers} stateSelected={stateSelected} setStateSelected={setStateSelected} user={user} clientSelected={clientSelected} listFollowed={listFollowed} listByRisks={listByRisks} listByProcesses={listByProcesses} />
                    }
                    </>
                }

                {/* Buttons */}
                {toggle !== 1 && toggle !== 2 && toggle !== 5 &&
                    <div style={{ position: 'absolute', bottom: '9.5%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '33vw', zIndex: 5 }}>
                        {(toggleSuivi === 0 && clientSelected?.type_hab === 'Risk management') && stateSelected &&
                            <div style={{ width: '10vw' }}><Link className='link' to={`/create/risk/1`}> Modifier </Link></div>
                        }
                        {(toggleSuivi === 1 && (clientSelected?.type_hab === 'Contribution métier' || clientSelected?.type_hab === 'Risk management')) && stateSelected &&
                            <div style={{ width: '10vw' }}><Link className='link' to={`/create/risk/2`}> Modifier </Link></div>
                        }
                        {(toggleSuivi === 2 && (clientSelected?.type_hab === 'Gouvernance' || clientSelected?.type_hab === 'Risk management')) && stateSelected &&
                            <div style={{ width: '10vw' }}><Link className='link' to={`/create/risk/3`}> Modifier </Link></div>
                        }
                        {/* {(toggleSuivi === 3 && (clientSelected?.type_hab === 'Contribution métier' || clientSelected?.type_hab === 'Risk management')) &&
                        <div style={{ width: '10vw' }}><Link className='link' to={`/create/risk/4`}> Modifier </Link></div>
                    } */}

                    </div>
                }

                {tempState && toggle !== 5 &&
                    <>
                        <div style={{ position: 'absolute', bottom: '4%', right: '5.5%', fontWeight: 'bold', lineHeight: 2 }}>
                            {`Version du ${moment(stateSelected?.date_creation).format('LL')}`}
                        </div>
                        <div style={{ position: 'absolute', bottom: '7.5%', right: '6%', width: '8vw' }}>
                            <Button texte={`Retour à l'actuelle`} onClick={() => { setStateSelected(tempState); setTempState(null) }} />
                        </div>
                    </>
                }
                {!tempState && stateSelected?.iterations.length > 0 && toggle !== 1 && toggle !== 5 && toggle !== 2 &&//toggleSuivi !== 3 &&               
                    <div style={{ position: 'absolute', bottom: '7.5%', right: '6%', width: '8vw' }}>
                        <Button texte='Versions antérieures' onClick={() => { toggleDrawer() }} />
                    </div>
                }
                {!tempState && stateSelected && clientSelected && toggle !== 1 && toggle !== 2 && toggle !== 5 && user._id === '62699350bf7c7ded37e4dfdb' &&               
                    <div style={{ position: 'absolute', bottom: '7.5%', right: '3%', width: '0.8vw', lineHeight: '8px' }}>
                        <Button texte='x' onClick={() => { unfollow(clientSelected._id, stateSelected._id) }} />
                    </div>
                }
            </div>

            <Drawer
                title="Suivi des versions antérieures"
                placement='right'
                closable={true}
                onClose={toggleDrawer}
                visible={visible}
                key='versions'
            >
                {/* iterations.map */}
                {stateSelected?.iterations?.length > 0 &&
                    stateSelected?.iterations.map((it, i) => {
                        return (<p className='versions' onClick={() => { setTempState(stateSelected); setStateSelected(it); toggleDrawer() }} >Version du {moment(stateSelected?.date_creation).format('LL')} - {stateSelected?.replaced_by.firstname} {stateSelected?.replaced_by.lastname}{stateSelected?.replaced_by?.avatar && <img alt='avatar' className='avatar-right' src={stateSelected?.replaced_by.avatar} />}</p>)
                    })
                }

            </Drawer>

            <Drawer
                title="Risques à intégrer"
                placement='left'
                closable={true}
                size={'large'}
                onClose={toggleDrawerReport}
                visible={visibleReport}
                key='report-choice'

            >

                <PDFDownloadLink style={{ marginTop: 10, position: 'absolute', top: '0.2%', right: '10%', width: '25%', color: '#222', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} document={<MyDocument />} fileName="rapportAsterion.pdf">
                    {({ blob, url, loading, error }) =>
                        loading ? 'Loading document...' : 'Download now!'
                    }
                    <Button texte='Exporter le rapport' onClick={() => { toggleDrawerReport(); form.resetFields(); setListReport([]) }} />

                </PDFDownloadLink>

                <Checkbox style={{ marginLeft: 50, marginBottom: 20 }} checked={listReport.length > 0} onChange={() => { selectAll() }}>Tout sélectionner</Checkbox>


                {/* list.map */}
                {listByRisks.length > 0 &&

                    // listFollowed.map((risk, i)=>{
                    // return (

                    // <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                    //     <Checkbox onChange={()=>{ addOrRemove(risk) }}></Checkbox>
                    //     <p style={{margin: '5px 5px 5px 15px'}} className='risks' >{risk.intitule}</p>
                    // </div>
                    // )

                    listByRisks.map((e) => {
                        let listRisks = e.family.risks.sort((a, b) => a.code - b.code).map((f) => {
                            let listStates = f.states.sort((a, b) => a.code - b.code).map((g) => {
                                // console.log(g.proprietaire._id, ' - ', user._id)
                                return (
                                    <div key={g._id} style={{ marginLeft: 50, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <Checkbox checked={listReport?.find(e => e?._id === g?._id)} onChange={() => { addOrRemove(g) }}></Checkbox>
                                        <span style={{ cursor: 'pointer', margin: '5px 5px 5px 15px' }} onClick={() => { addOrRemove(g) }}>{f.code} · {g.intitule}</span>
                                    </div>
                                )
                            })
                            return (
                                <div className='card'>
                                    {clientSelected.level_more && <span>{f.code} · {f.libelle}</span>}
                                    {listStates}
                                </div>
                            )
                        })
                        return (
                            <Form form={form}>
                                <Form.Item>
                                    <div className='card'>
                                        <span className='tag-top'>{e.family.code} · {e.family.libelle}</span>
                                        {listRisks}
                                    </div>
                                </Form.Item>
                            </Form>
                        )

                    })

                }

            </Drawer>

        </>
    )


}

export default Suivi;