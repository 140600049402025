import React, { useState, useEffect } from 'react'
import back from '../public/images/home-fond2.jpeg'
import '../public/styles/home.css'
import Nav from '../components/Nav'
import logoAsterion from '../public/images/ASTERION PARTNERS.png'
import { useParams } from 'react-router-dom'

//ant
import { LaptopOutlined } from '@ant-design/icons';

//REDUX_______________________
import { useSelector } from 'react-redux'

//COMPONENTS
import Choice from '../components/Choice'

const Home = () => {

    const [isDesktop, setIsDesktop] = useState(false)

    const { loaded } = useParams()

    const [ready, setReady] = useState(loaded ? true : false)

    const user = useSelector (({ user })=> user)
    const clientSelected = useSelector (({ clientSelected })=> clientSelected)


//LISTENERS
    // useEffect(()=>{console.log('isDesktop in Home : ', isDesktop)}, [isDesktop])
    //useEffect(()=>{if (clientSelected) {console.log('clientSelected in Home : ', clientSelected)}}, [clientSelected])

    return (
        <>
            {!isDesktop &&
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 50000, position: 'absolute', textAlign: 'center', fontSize: 20, height: '100%', width: '100%', backgroundColor: '#333', opacity: 0.99, color: '#FFF', fontWeight: 'bold'}}>
                    <LaptopOutlined style={{fontSize: 200, color: "#EDB30D", marginBottom: 40}}/>
                    <span style={{margin: '20px 0px'}}>Votre écran est un peu petit : cela risque d'empêcher le rendu de certains graphiques et de données modélisées.</span>
                    <span style={{margin: '20px 0px'}}>Agrandissez la fenêtre du navigateur ou installez-vous sur un écran de taille supérieure pour profiter pleinement de l'application 😀</span> 
                </div>
            }

            <Nav ready={ready} setReady={setReady} isDesktop={isDesktop} setIsDesktop={setIsDesktop} />

            <div className="first" style={{
                height: '95vh',
                backgroundImage: `url(${back})`,
                backgroundColor: 'black',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}>

                {!user &&
                <>
                    <h1 style={{position: 'absolute', left: '5%', top: '35%', color: '#FFF', fontSize: 80}}>Risk & Tools</h1>
                    <h2 style={{position: 'absolute', left: '5%', top: '50%', color: '#FFF', fontSize: 60, width: '40%'}}>Structurez et pilotez votre cartographie des risques</h2>
                </>
                }

                {user &&
                    <Choice ready={ready} user={user} clientSelected={clientSelected} />
                }

            <img alt='logo' className='logo-asterion' src={logoAsterion} style={{
                height: '20vh',
                }}/>

            </div>

        </>
    )
    

}

export default Home;