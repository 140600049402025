import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import '../public/styles/suivi.css'

//ant
import { LaptopOutlined } from '@ant-design/icons';

//COMPONENT
import Nav from '../components/Nav'
import Habilitations from '../components/Habilitations'
import ManageRiskState from '../components/ManageRiskState'
import AddProfileR from '../components/AddProfileR'
import SideInfos from '../components/SideInfos'
import AddInsideState from '../components/AddInsideState'

//REDUX
import { useDispatch, useSelector } from 'react-redux'


const Create = () => {

    const [ready, setReady] = useState(true)

    const { typo, stepFromSuivi, clientId, tokenFromUrl } = useParams()
    // console.log('----------------------- params in create', {typo, stepFromSuivi, clientId, tokenFromUrl})

    //state reverse list docs uploaded
    const [documentsUploaded, setDocumentsUploaded] = useState([])

    //acualisation via addItem
    const [actualisation, setActualisation] = useState(false)

    //state open creation profile
    const [crea, setCrea] = useState(false)

    const dispatch = useDispatch()

    const user = useSelector(({ user }) => user)
    const clientSelected = useSelector(({ clientSelected }) => clientSelected)
    const listFollowed = useSelector(({ listFollowed }) => listFollowed)
    const listByRisks = useSelector(({ listByRisks }) => listByRisks)
    const listByProcesses = useSelector(({ listByProcesses }) => listByProcesses)
    const stateSelected = useSelector(({ stateSelected }) => stateSelected)
    const clientUsers = useSelector(({ clientUsers }) => clientUsers)

    const auditSelected = useSelector(({ auditSelected }) => auditSelected)
    const stateId = useSelector(({ stateId }) => stateId)
    const audits = useSelector(({ audits }) => audits)

    const [allProcessesAndFamilies, setAllProcessesAndFamilies] = useState()
    const [allRisksAndFamilies, setAllRisksAndFamilies] = useState()

    useEffect(() => { console.log('listFollowed in Create : ', listFollowed) }, [listFollowed])
    useEffect(() => { console.log('auditSelected in Create : ', auditSelected) }, [auditSelected])


    // console.log('stateSelected in Create Out of USEEFFECT : ', stateSelected)
    // console.log('allProcessesAndFamilies ', allProcessesAndFamilies)
    // console.log('clientSelected ',clientSelected)
    // console.log(listFollowed)

    //listener stateSelected in Create
    // useEffect(()=>{console.log('stateSelected in Create : ', stateSelected)}, [stateSelected])
    // useEffect(()=>{console.log('actualisation : ', actualisation)}, [actualisation])


    //useEffect get risks and processes to select if needed
    useEffect(() => {
        const fetchAllRisksAndFamilies = async () => {
            const data = await fetch(`${process.env.REACT_APP_BACK_END}/clients/get-risks/${clientSelected?._id}`)
            const json = await data.json()
            if (json.result) {
                //console.log('FETCH risks in Create : ', json.allRisksAndFamilies)
                setAllRisksAndFamilies(json.allRisksAndFamilies)
                dispatch({ type: 'allrisks', list: json.allRisksAndFamilies })
            }
        }
        fetchAllRisksAndFamilies()

        const fetchAllProcessesAndFamilies = async () => {
            const data = await fetch(`${process.env.REACT_APP_BACK_END}/clients/get-processes/${clientSelected?._id}`)
            const json = await data.json()
            if (json.result) {
                //console.log('FETCH processes in Create : ', json.allProcessesAndFamilies)
                setAllProcessesAndFamilies(json.allProcessesAndFamilies)
                dispatch({ type: 'allprocesses', list: json.allProcessesAndFamilies })
            }
        }
        fetchAllProcessesAndFamilies()

    }, [actualisation])

    const [isDesktop, setIsDesktop] = useState(false)

    return (
        <>
            {!isDesktop &&
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 50000, position: 'absolute', textAlign: 'center', fontSize: 20, height: '100%', width: '100%', backgroundColor: '#333', opacity: 0.99, color: '#FFF', fontWeight: 'bold' }}>
                    <LaptopOutlined style={{ fontSize: 200, color: "#EDB30D", marginBottom: 40 }} />
                    <span style={{ margin: '20px 0px' }}>Votre écran est un peu petit : cela risque d'empêcher le rendu de certains graphiques et de données modélisées.</span>
                    <span style={{ margin: '20px 0px' }}>Agrandissez la fenêtre du navigateur ou installez-vous sur un écran de taille supérieure pour profiter pleinement de l'application 😀</span>
                </div>
            }

            <Nav ready={ready} setReady={setReady} actualisation={actualisation} isDesktop={isDesktop} setIsDesktop={setIsDesktop} />

            <div className="global">


                {typo === "profile" && !crea &&
                    <>
                        <Habilitations crea={crea} setCrea={setCrea} />
                    </>
                }
                {typo === "profile" && crea &&
                    <>
                        <h1>Création d'un profil</h1>
                        <AddProfileR crea={crea} setCrea={setCrea} clientSelected={clientSelected} />
                    </>

                }
                {/* {typo === 'process' &&
                    <>
                    <h1>Création d'un processus métier</h1>
                    <AddItem item='process'/>
                    </>
                }
                {typo === 'family' &&
                    <>
                    <h1>Création d'une famille de risque ou d'un risque</h1>
                    <AddItem item='family'/>
                    </>
                } */}

                {typo === 'risk' &&
                    <>
                        <h1 style={{ position: 'absolute', top: '12%' }}>Création ou modification d'un risque</h1>
                        <ManageRiskState actualisation={actualisation} setActualisation={setActualisation} documentsUploaded={documentsUploaded} setDocumentsUploaded={setDocumentsUploaded} stepFromSuivi={stepFromSuivi} allProcessesAndFamilies={allProcessesAndFamilies} allRisksAndFamilies={allRisksAndFamilies} clientUsers={clientUsers} stateSelected={stateSelected} user={user} clientSelected={clientSelected} listFollowed={listFollowed} listByRisks={listByRisks} listByProcesses={listByProcesses} />
                        {/* <SideInfos documentsUploaded={documentsUploaded} setDocumentsUploaded={setDocumentsUploaded} slotToSave='suivi' side='right' screen='informations' stateSelected={stateSelected} user={user} clientSelected={clientSelected} listFollowed={listFollowed} listByRisks={listByRisks} listByProcesses={listByProcesses} /> */}
                    </>
                }
                {typo === "action" &&
                    <>
                        <h1 style={{ position: 'absolute', top: '12%' }}>Nouveau plan d'action sur le risque {stateSelected?.intitule}</h1>
                        <AddInsideState documentsUploaded={documentsUploaded} setDocumentsUploaded={setDocumentsUploaded} element='action' allProcessesAndFamilies={allProcessesAndFamilies} allRisksAndFamilies={allRisksAndFamilies} clientUsers={clientUsers} stateSelected={stateSelected} user={user} clientSelected={clientSelected} listFollowed={listFollowed} listByRisks={listByRisks} listByProcesses={listByProcesses} />
                        <SideInfos documentsUploaded={documentsUploaded} setDocumentsUploaded={setDocumentsUploaded} slotToSave='action' side='right' screen='actions' stateSelected={stateSelected} user={user} clientSelected={clientSelected} listFollowed={listFollowed} listByRisks={listByRisks} listByProcesses={listByProcesses} />

                    </>
                }
                {typo === "incident" &&
                    <>
                        <h1 style={{ position: 'absolute', top: '12%' }}>Nouvel incident sur le risque {stateSelected?.intitule}</h1>
                        <AddInsideState documentsUploaded={documentsUploaded} setDocumentsUploaded={setDocumentsUploaded} element='incident' allProcessesAndFamilies={allProcessesAndFamilies} allRisksAndFamilies={allRisksAndFamilies} clientUsers={clientUsers} stateSelected={stateSelected} user={user} clientSelected={clientSelected} listFollowed={listFollowed} listByRisks={listByRisks} listByProcesses={listByProcesses} />
                        <SideInfos documentsUploaded={documentsUploaded} setDocumentsUploaded={setDocumentsUploaded} slotToSave='incident' side='right' screen='incidents' stateSelected={stateSelected} user={user} clientSelected={clientSelected} listFollowed={listFollowed} listByRisks={listByRisks} listByProcesses={listByProcesses} />

                    </>
                }
                {typo === "audit" &&
                    <>
                        <h1 style={{ position: 'absolute', top: '12%' }}>{(auditSelected && Object.keys(auditSelected)?.length > 0) ? `Modifier l'audit ${auditSelected?.libelle}`: 'Débuter un audit'}</h1>
                        <AddInsideState audits={audits} auditSelected={auditSelected} documentsUploaded={documentsUploaded} setDocumentsUploaded={setDocumentsUploaded} element='audit' allProcessesAndFamilies={allProcessesAndFamilies} allRisksAndFamilies={allRisksAndFamilies} clientUsers={clientUsers} stateSelected={stateSelected} user={user} clientSelected={clientSelected} listFollowed={listFollowed} listByRisks={listByRisks} listByProcesses={listByProcesses} />
                        {/* <SideInfos documentsUploaded={documentsUploaded} setDocumentsUploaded={setDocumentsUploaded} slotToSave='audit' side='right' screen='audits' stateSelected={stateSelected} user={user} clientSelected={clientSelected} listFollowed={listFollowed} listByRisks={listByRisks} listByProcesses={listByProcesses} /> */}

                    </>
                }
                {typo === "reco" &&
                    <>
                        <h1 style={{ position: 'absolute', top: '12%' }}>Analyse du risque {listFollowed?.find(e=>e?._id === stateId)?.intitule}</h1>
                        <AddInsideState audits={audits} auditSelected={auditSelected} stateId={stateId} documentsUploaded={documentsUploaded} setDocumentsUploaded={setDocumentsUploaded} element='reco' allProcessesAndFamilies={allProcessesAndFamilies} allRisksAndFamilies={allRisksAndFamilies} clientUsers={clientUsers} stateSelected={stateSelected} user={user} clientSelected={clientSelected} listFollowed={listFollowed} listByRisks={listByRisks} listByProcesses={listByProcesses} />
                        {/* <SideInfos documentsUploaded={documentsUploaded} setDocumentsUploaded={setDocumentsUploaded} slotToSave='audit' side='right' screen='audits' stateSelected={stateSelected} user={user} clientSelected={clientSelected} listFollowed={listFollowed} listByRisks={listByRisks} listByProcesses={listByProcesses} /> */}

                    </>
                }
            </div>

        </>
    )


}

export default Create;